.guestDialogDiv{
    position: absolute;
    margin: auto;
    top: 0; right: 0; bottom: 0; left: 0;
    height: fit-content;
    width: 450px;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 14px;
    overflow: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.guest_label{
    margin: 0;
    font-weight: bold;
}

.guest_button{
    margin-top: 20px;
    margin-left: auto; margin-right: auto;
    height: 40px;
    width: 90%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 12px;
    border: solid #e3e3e3;
    cursor: pointer;
}

.guest_button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.guest_input{
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  margin-top: 5%;
  padding-left: 20px;
  width: 90%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.guest_button, .guest_input {
    box-sizing: border-box;
  }
  