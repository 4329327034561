.slideshow-container {
    position: relative;
    margin: auto;
    height: 50vh;
    background-color: #e3e3e3;
    cursor: pointer;
  }
  
  .slideshow-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    object-fit: fill;
    object-position: center;
  }
  
  .slideshow-container img.active {
    opacity: 1;
  }

  .prev {
    position: absolute;
    margin: auto;
    top: 40%;
    left: 0;
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 38px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    cursor: pointer;
  }
  
  .next {
    position: absolute;
    margin: auto;
    top: 40%;
    right: 0;
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 38px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    cursor: pointer;
  }

  .prev:hover,
    .next:hover {
    color: gray;
    }

    .shimmer-wrapper {
      position: relative;
      width: 100%;
      height: 50vh; /* Match slideshow-container height */
      background-color: #e3e3e3; /* Match the slideshow background */
      cursor: pointer;
      overflow: hidden;
    }
    
    .shimmer {
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: shimmer 1.5s infinite linear;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px; /* Optional for rounded corners */
    }
    
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
    
    .hidden {
      display: none;
    }
    