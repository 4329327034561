/** @format */

body {
  overflow-x: hidden;
  background-color: whitesmoke;
}

.homeMenuDiv {
  width: 100%;
  display: flex;
  background-color: white;
  display: flex; /* Ensures items are aligned horizontally */
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents items from wrapping to the next line */
  gap: 10px; /* Optional: Adds space between items */
  scrollbar-width: thin;
}

.homeMenuListDiv{
  margin: 0 15px;
}

.homeMenuText{
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.homeMenuText:hover{
  box-shadow: 0 2px 0px #5d3fd3; /* Blue glowing shadow */
}

.coinDiv {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 1px solid #e3e3e3;
  align-items: center;
  display: flex;
  background-color: white;
}

.coinImg{
  position: relative;
  margin: 0; /* This will push the element to the right */
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 10;
}
.coinLabel{
  margin: 0; margin-left: 5px;
  font-weight: bold;
}

.merchDiv{
  position: relative;
  margin: 0; margin-left: 2%; margin-right: 2%;
  width: 96%;
  height: fit-content;
  border: solid 2px #e3e3e3;
  justify-content: center;
  display: flex;
  background-color: white;
}

.merchItem{
  margin-right: 35px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e3e3e3; border-top: 0; border-bottom: 0;
  padding-left: 15px; padding-right: 15px; padding-bottom: 5px;
  cursor: pointer;
}

.merchItem:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.merchImg{
  max-width: 200px;
  height: 200px;
  object-fit: cover;
}

.merchLabel{
  margin: 0; 
  margin-top: 5px; 
  font-weight: bold;
  font-family: monospace, sans-serif;
}

.merchView{
  width: 100%;
  padding-left: 15px; padding-right: 15px;
  height: 1px; 
  background-color: #e3e3e3;
}

.merchLabel2{
  margin-top: 3px;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Use a cartoonish font */
  color: #ba55d3;
  font-weight: bold; /* Make the text bold */
  text-transform: uppercase; /* Uppercase the text for a cartoonish look */
}

.merchButton {
  width: 100%;
  height: 35px;
  background-color: white;
  color: #000;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ccc;
}

.merchButton:hover {
  background-color: #ba55d3;
  color: #fff;
}

.cashTitle{
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.cash-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.cashLabel{
  margin: 0; 
  margin-top: 5px; 
  font-weight: bold;
}

.cashTable{
  position: relative;
  width: 20%; /* Set each item to occupy 20% of the container width */
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px 10px 15px;
}

.cashImg{
  max-width: 200px;
  height: 200px;
  object-fit: cover;
}

.cashView{
  width: 100%;
  height: 1px; 
  background-color: #e3e3e3;
}

.cashTable:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.priceDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}

.coinImg2{
  position: relative;
  margin: 0; /* This will push the element to the right */
}

.cashLabel2{
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Use a cartoonish font */
  color: #ba55d3;
  font-weight: bold; /* Make the text bold */
  margin-left: 3px;
  text-transform: uppercase; /* Uppercase the text for a cartoonish look */
}

.FooterDiv {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  background-color: black;
}

.copyrightLabel2 {
  margin: 0;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
}

.homeFooterDiv {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  background-color: black;
}

.home-item-container {
  position: relative;
  width: calc(95%);
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  display: flex;
  gap: 15px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.homeItemLabel{
  margin: 0; 
  margin-top: 5px; 
  font-weight: bold;
}

.homeItemLabel2 {
  max-width: 100px;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Use a cartoonish font */
  color: #ba55d3;
  font-weight: bold; /* Make the text bold */
  margin-left: 3px;
  text-transform: uppercase; /* Uppercase the text for a cartoonish look */
  
  /* Ellipsis styling */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide text that overflows */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}


.homeItemtable{
  position: relative;
  width: 20%; /* Set each item to occupy 20% of the container width */
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
}

.homeItemDiv{
  height: 200px;
  width: 200px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
}

.homeItemImg{
  max-width: 200px;
  height: 200px;
  object-fit: cover;
}

.homeItemView{
  width: 100%;
  height: 1px; 
  background-color: #e3e3e3;
}

.homeItemTable:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 900px) {
  .loginInput {
    display: none;
  }

  .introBox {
    width: 100%;
  }

  .loginBody {
    position: absolute;
    margin: auto;
    background: #5d3fd3;
  }

  .google_promo {
    margin: auto;
    width: 150px;
    height: 75px;
    position: absolute;
    top: 65%;
    left: 0;
    right: 0;
    cursor: pointer;
    object-fit: cover;
  }
  
  .apple_promo {
    margin: auto;
    width: 150px;
    height: 75px;
    position: absolute;
    top: 73%;
    left: 0;
    right: 0;
    cursor: pointer;
    object-fit: cover;
  }

  .homeButtonAvailability {
    width: 100%;
    height: 35px;
    background-color: white;
    color: #000;
    border: none;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    border: 1px solid #ccc;
  }

}
